<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep1Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >
    <CustomRadioGroup
      v-model="values.niche"
      :options="nicheOptions"
      name="niche"
      label="Niche"
    />
    <FormulateInput
      type="select"
      name="type"
      label="Type"
      :options="PROPERTY_TYPES"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.building_type"
      :options="buildingTypeOptions"
      name="building_type"
      label="Type bebouwing"
    />
    <FormulateInput
      type="number"
      name="build_year"
      label="Bouwjaar"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0 hide-number-input-arrows']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
    </FormulateInput>
    <FormulateInput
      type="number"
      name="renovation_year"
      label="Renovatiejaar"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0 hide-number-input-arrows']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="translatable"
      name="renovation_description"
      label="Details renovatie"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3 tw-w-full']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :outer-class="['!tw-my-2 tw-flex tw-items-center']"
      :input-class="['tw-my-0']"
      :element-class="['tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="state"
      placeholder="Staat"
      label="Staat"
      :options="PROPERTY_STATES"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="select"
      name="orientation"
      placeholder="Oriëntatie"
      label="Oriëntatie"
      :options="ORIENTATION_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <CustomRadioGroup
      v-model="values.sea_view"
      :options="YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS"
      name="sea_view"
      label="Zeezicht"
    />
    <FormulateInput
      type="select"
      name="environment"
      placeholder="Omgeving"
      label="Omgeving"
      :options="ENVIRONMENT_OPTIONS"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <FormulateInput
      type="number"
      name="parking_spots"
      label="Aantal parkeerplaatsen"
      min="0"
      step="1"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="number"
      name="floor"
      label="Verdieping van het vastgoed"
      min="0"
      step="1"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="number"
      name="number_of_floors"
      label="Aantal verdiepingen in het gebouw"
      min="0"
      step="1"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    />
    <FormulateInput
      type="number"
      name="surface_plot"
      label="Perceeloppervlakte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m²
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="surface_livable"
      label="Bewoonbare oppervlakte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m²
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="surface_built"
      label="Bebouwde oppervlakte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m²
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="surface_plot_width"
      label="Perceelbreedte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="surface_plot_depth"
      label="Perceeldiepte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m
      </div>
    </FormulateInput>
    <FormulateInput
      type="number"
      name="facade_width"
      label="Gevelbreedte"
      min="0"
      step=".01"
      :outer-class="['!tw-my-2']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :input-class="['tw-rounded-tr-none tw-rounded-br-none tw-my-0 hide-number-input-arrows']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center md:tw-w-2/3']"
    >
      <div slot="suffix" class="input-unit !tw-my-0">
        m
      </div>
    </FormulateInput>
    <FormulateInput
      type="autocomplete"
      autoCompleteType="contact"
      name="syndic"
      label="Syndicus"
      :outer-class="['!tw-my-2']"
      :input-class="['tw-my-0']"
      :wrapper-class="['md:tw-grid tw-gap-x-2 tw-grid-cols-3']"
      :label-class="['tw-block md:tw-flex tw-items-center']"
      :element-class="['tw-flex tw-items-center tw-col-span-2']"
    />
    <div class="tw-mt-8 tw-flex tw-justify-end">
      <FormulateErrors />
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full md:tw-w-auto']"
        :disabled="isLoading"
        outer-class="tw-my-2 tw-w-full md:tw-w-auto"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-arrow-right'
          ]"
        />
        Volgende stap
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import {
  ENVIRONMENT_OPTIONS,
  NICHES,
  ORIENTATION_OPTIONS,
  PROPERTY_STATES,
  YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS
} from '@/forms/selectOptions'
import { PROPERTY_TYPES } from '@/forms/schemas/portfolio/property/address'
import { getPropertyContacts, updateProperty, updatePropertyTechnical } from '@/services/properties'
import CustomRadioGroup from '@/components/base/CustomRadioGroup'
import { updateSyndic } from '@/utils/notaryAndSyndic'

export default {
  name: 'WizardDossierDetailsStep1',
  components: {
    CustomRadioGroup
  },
  constants: {
    ENVIRONMENT_OPTIONS,
    NICHES,
    ORIENTATION_OPTIONS,
    PROPERTY_STATES,
    PROPERTY_TYPES,
    YES_NO_UNKNOWN_DOSSIER_DETAILS_WIZARD_OPTIONS
  },
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {}
    }
  },
  computed: {
    nicheOptions () {
      return [
        {
          value: 1,
          label: 'Woonvastgoed',
          default: false,
          hidden: false
        },
        {
          value: 2,
          label: 'Bedrijfsvastgoed',
          default: false,
          hidden: false
        }
      ]
    },
    buildingTypeOptions () {
      return [
        {
          value: 0,
          label: 'Onbekend',
          default: true,
          hidden: true
        },
        {
          value: 1,
          label: 'Open',
          default: false,
          hidden: false
        },
        {
          value: 2,
          label: 'Halfopen',
          default: false,
          hidden: false
        },
        {
          value: 3,
          label: 'Gesloten',
          default: false,
          hidden: false
        }
      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      this.values = {
        ...this.initialValues,
        state: this.initialValues.state.id
      }
      return await this.fetchSyndic()
    },
    async fetchSyndic () {
      const syndicPayload = { entityId: this.propertyId, params: { contact_role: 'syndic' } }
      const response = await getPropertyContacts(syndicPayload)
      const syndic = response?.data?.results?.[0]?.contact || null
      this.$set(this.values, 'syndic', syndic)
      this.$set(this.values, 'previous_syndic_property_contact', response?.data?.results?.[0] || null)
      return response
    },
    async submit (data) {
      try {
        const {
          niche,
          type,
          previous_syndic_property_contact,
          syndic,
          building_type,
          build_year,
          renovation_year,
          renovation_description,
          state,
          orientation,
          sea_view,
          environment,
          surface_plot,
          surface_livable,
          surface_built,
          surface_plot_width,
          surface_plot_depth,
          facade_width,
          parking_spots,
          floor,
          number_of_floors
        } = data
        const propertyPayload = {
          niche,
          type
        }
        const technicalPayload = {
          building_type,
          build_year,
          renovation_year,
          renovation_description,
          state,
          orientation,
          sea_view,
          environment,
          surface_plot,
          surface_livable,
          surface_built,
          surface_plot_width,
          surface_plot_depth,
          facade_width,
          parking_spots,
          floor,
          number_of_floors
        }
        const propertyResponse = await updateProperty(this.propertyId, propertyPayload)
        const technicalResponse = await updatePropertyTechnical(this.propertyId, technicalPayload)
        const syndicResponse = await updateSyndic(this.propertyId, previous_syndic_property_contact, syndic)
        await this.fetchSyndic() // Make sure the values are updated on the component
        this.$emit('finished', { niche })
        return [propertyResponse, technicalResponse, syndicResponse]
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep1Form')
        throw error
      }
    }
  }
}
</script>
