<template>
  <FormulateForm
    #default="{ isLoading }"
    v-model="values"
    name="wizardDossierDetailsStep7Form"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="submit"
  >

    <h3>Gunstige factoren</h3>
    <FormulateInput
      type="translatable"
      name="positive_factors"
      :max-lines="8"
      :input-class="['tw-h-60']"
    />

    <h3>Ongunstige factoren</h3>
    <FormulateInput
      type="translatable"
      name="negative_factors"
      :max-lines="8"
      :input-class="['tw-h-60']"
    />

    <p class="tw-italic">Tip: begin voor elke factor een nieuwe regel. Op deze manier wordt de inhoud correct weergegeven in het gegenereerde schattingsrapport.</p>

    <div class="tw-grid tw-grid-cols-2 md:tw-flex tw-justify-end tw-gap-x-2">
      <FormulateErrors />
      <FormulateInput
        type="button"
        :input-class="['tw-w-full tw-bg-gray-500']"
        :disabled="saving"
        @click="goBack"
      >
        <i class="fas tw-mr-2 fa-arrow-left" />
        Vorige <span class="tw-hidden md:tw-inline">stap</span>
      </FormulateInput>
      <FormulateInput
        type="submit"
        title="Volgende stap"
        :input-class="['tw-w-full']"
        :disabled="isLoading"
      >
        <i
          :class="[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan <span class="tw-hidden md:tw-inline">en afsluiten</span>
      </FormulateInput>
    </div>
  </FormulateForm>
</template>

<script>
import { updateProperty } from '@/services/properties'

export default {
  name: 'WizardDossierDetailsStep7',
  props: {
    initialValues: {
      type: Object
    },
    propertyId: {
      type: [Number, String]
    }
  },
  data () {
    return {
      values: {},
      saving: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.values = { ...this.initialValues }
    },
    async goBack () {
      this.saving = true
      const response = await this.submit(this.values, false)
      this.saving = false
      return response
    },
    async submit (data, goForward = true) {
      try {
        const {
          positive_factors,
          negative_factors
        } = data
        const payload = {
          positive_factors,
          negative_factors
        }
        const response = await updateProperty(this.propertyId, payload)
        if (goForward) this.$emit('finished')
        else this.$emit('goBack')
        return response
      } catch (error) {
        this.$formulate.handle(error, 'wizardDossierDetailsStep7Form')
        throw error
      }
    }
  }
}
</script>
