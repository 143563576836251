var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":_vm.title}},[_c('FormulateForm',{attrs:{"name":"subPropertyForm"},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"property","name":"property","label":"Subdossier","placeholder":"Zoeken naar pand","outer-class":"tw-my-0"}}),_c('FormulateInput',{attrs:{"type":"radio","label":"Commerciële ontdubbeling","name":"is_commercial_deduplication","options":{
          is_commercial_yes: 'Ja',
          is_commercial_no: 'Nee'
      },"input-class":['tw-my-1'],"element-class":['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-items-center']}}),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"outer-class":"tw-mt-0"}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Opslaan ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }