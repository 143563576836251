import { createPropertyContact, deletePropertyContact, updatePropertyContact } from '@/services/properties'
import { getContactRoles } from '@/services/contacts'

export async function doPropertyContactUpdate (propertyId, previousPropertyContact, currentContact, contactRoleId) {
  if (previousPropertyContact?.contact?.id === currentContact?.id) return
  if (!previousPropertyContact && currentContact) {
    const payload = {
      contact: currentContact.id,
      contact_role: contactRoleId
    }
    return await createPropertyContact(propertyId, payload)
  }
  if (previousPropertyContact && !currentContact) {
    return await deletePropertyContact(propertyId, previousPropertyContact.id)
  }
  if (previousPropertyContact?.contact?.id !== currentContact?.id) {
    const payload = {
      contact: currentContact.id
    }
    return await updatePropertyContact(propertyId, previousPropertyContact.id, payload)
  }
}

export async function updateNotaries (propertyId, previousNotaryOfBuyersPropertyContact, notaryOfBuyers, previousNotaryOfOwnersPropertyContact, notaryOfOwners) {
  const response = await getContactRoles()
  const notaryOfBuyersContactRoleId = response?.data?.results.find(item => item.key === 'notary_of_buyers').id
  const notaryOfOwnersContactRoleId = response?.data?.results.find(item => item.key === 'notary_of_owners').id
  const responses = await Promise.all(
    [
      doPropertyContactUpdate(propertyId, previousNotaryOfBuyersPropertyContact, notaryOfBuyers, notaryOfBuyersContactRoleId),
      doPropertyContactUpdate(propertyId, previousNotaryOfOwnersPropertyContact, notaryOfOwners, notaryOfOwnersContactRoleId)
    ]
  )
  return responses
}

export async function updateSyndic (propertyId, previousSyndicPropertyContact, syndic) {
  const response = await getContactRoles()
  const syndicRoleId = response?.data?.results.find(item => item.key === 'syndic').id
  return await doPropertyContactUpdate(propertyId, previousSyndicPropertyContact, syndic, syndicRoleId)
}
