<template>
    <BaseModal ref="modal" :title="title">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="subPropertyForm"
        @submit="submit"
      >
        <FormulateInput
          type="autocomplete"
          auto-complete-type="property"
          name="property"
          label="Subdossier"
          placeholder="Zoeken naar pand"
          outer-class="tw-my-0"
        />
        <FormulateInput
          type="radio"
          label="Commerciële ontdubbeling"
          name="is_commercial_deduplication"
          :options="{
              is_commercial_yes: 'Ja',
              is_commercial_no: 'Nee'
          }"
          :input-class="['tw-my-1']"
          :element-class="['tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-items-center']"
        />

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-0"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
</template>

<script>
import { updateProperty } from '@/services/properties'
import { successModal } from '@/modalMessages'

export default {
  name: 'PropertySubPropertyModal',
  props: {
    propertyId: {
      type: [Number, String]
    },
    title: {
      type: String
    }
  },
  data () {
    return {
      values: {}
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    async submit (data) {
      data.is_managed_by_parent = data.is_commercial_deduplication === 'is_commercial_yes'
      data.parent = this.propertyId
      const response = await updateProperty(data.property.id, data)
      successModal('Pand werd succesvol ingesteld als moederpand')
      this.$emit('subPropertyLinked')
      this.$refs.modal.hide()
      return response
    }
  }
}
</script>
